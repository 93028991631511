<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增':'修改'" :close-on-click-modal="false" @closed="cancel" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" v-if="form" :disabled="disabled">
        <el-form-item label="属性名" prop="name">
          <el-input v-model.trim="form.name" style="width:300px" :maxlength="20" />
        </el-form-item>
        <el-form-item label="属性值" prop="dataEnum">
          <div class="fc-g">提示：多个属性值请用“|”（不包括引号）隔开。</div>
          <el-input v-model.trim="form.dataEnum" style="width:300px" :maxlength="250" />
        </el-form-item>
        <el-form-item label="所属分类" prop="categoryId">
          <tree-picker  v-model="form.categoryId" url="api/category/tree" style="width:300px"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/propertyDefine";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        name: null,
        dataEnum: null,
        categoryId:null
      },
      rules: {
        name: [
          { required: "true", message: "属性名为必填项", trigger: "blur" }
        ],
        dataEnum: [
          { required: "true", message: "属性值为必填项", trigger: "blur" }
        ],
        categoryId: [
          { required: "true", message: "请选择所属分类", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          add(this.form)
            .then(res => {
              this.dialog = false;
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.dialog = false;
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        dataEnum: null,
        categoryId:null
      };
    }
  }
};
</script>

